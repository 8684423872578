import Vue from 'vue'
const mapApi = Vue.prototype.mapApi;
//获取底图资源
//底图可以替换为wmts的高清底图
var baseMapSource = {
  baseMapLayer: [],

  //每次初次使用前需要初始化该类，拿到图层数组，放入map中
  //替换底图再次用到时就不需要调用该函数了
  newBaseMapLayer: function () {
    let layer1 = new mapApi.TileLayer({
      name: "底图",
      visible: true,
      id: "baseMap",
    });
    let layer2 = new mapApi.TileLayer({
      name: "底图注记",
      visible: true,
      id: "baseMap_cva",
    });
    this.baseMapLayer = [layer1, layer2];
    return this.baseMapLayer;
  },

  addSourceToMap: function (baseMapType) {
    let source = baseMapSource.getBaseMapSource(baseMapType)
    switch (baseMapType) {
      case Vue.prototype.mapEnum.baseLayerType.TDTvector:
      case Vue.prototype.mapEnum.baseLayerType.TDTimage:
      case Vue.prototype.mapEnum.baseLayerType.GDImage:
      case Vue.prototype.mapEnum.baseLayerType.TDTvector_blue:
        baseMapSource.baseMapLayer[0].setSource(source[0]);
        baseMapSource.baseMapLayer[1].setSource(source[1]);
        break;
      case Vue.prototype.mapEnum.baseLayerType.NVector:
      case Vue.prototype.mapEnum.baseLayerType.NVimage:
        baseMapSource.baseMapLayer[0].setSource(source[0]);
        baseMapSource.baseMapLayer[1].setSource(null);
        break;
      case Vue.prototype.mapEnum.baseLayerType.OSMvector:
        baseMapSource.baseMapLayer[0].setSource(source[0]);
        baseMapSource.baseMapLayer[1].setSource(null);
        break;
    };
  },

  // addSourceToMap2: function (baseMapType, imgLayer, noteLayer) {
  //   let source = baseMapSource.getBaseMapSource(baseMapType)
  //   switch (baseMapType) {
  //     case Vue.prototype.mapEnum.baseLayerType.TDTvector:
  //     case Vue.prototype.mapEnum.baseLayerType.TDTimage:
  //       imgLayer.setSource(source[0]);
  //       noteLayer.setSource(source[1]);
  //       break;
  //     case Vue.prototype.mapEnum.baseLayerType.NVector:
  //     case Vue.prototype.mapEnum.baseLayerType.Nimage:
  //       imgLayer.setSource(source[0]);
  //       noteLayer.setSource(null);
  //       break;
  //   };
  // },

  getBaseMapSource: function (baseMapType) {
    let Source;
    switch (baseMapType) {
      case Vue.prototype.mapEnum.baseLayerType.TDTvector:
        Source = baseMapSource.getTDvecLayerXYZ();
        break;
      case Vue.prototype.mapEnum.baseLayerType.TDTvector_blue:
        Source = baseMapSource.getTDvecLayerXYZ_blue();
        break;
      case Vue.prototype.mapEnum.baseLayerType.TDTimage:
        Source = baseMapSource.getTDImageLayerXYZ();
        break;
      case Vue.prototype.mapEnum.baseLayerType.NVvector:
        Source = baseMapSource.getArcGisImageTitl();
        break;
      case Vue.prototype.mapEnum.baseLayerType.NVimage:
        Source = baseMapSource.getArcGisImageTitl();
        break;
      case Vue.prototype.mapEnum.baseLayerType.GDImage:
        Source = baseMapSource.getDGImage();
        break;
      case Vue.prototype.mapEnum.baseLayerType.OSMvector:
        Source = baseMapSource.getOSMvector();
        break;
    };
    return Source;
  },


  /**
   * 天地图矢量
   */
  getTDvecLayerXYZ: function () {
    let projection = mapApi.proj.get("EPSG:4326");
    let projectionExtent = projection.getExtent();
    let size = mapApi.olExtent.getWidth(projectionExtent) / 256;
    let resolutions = [];

    for (let z = 2; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z);
    }

    let source1 = new mapApi.WMTSSource({
      name: "0-20级底图",
      url: "http://t{0-6}.tianditu.gov.cn/vec_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "vec",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      })
    });
    let source2 = new mapApi.WMTSSource({
      type: "baseMap",
      name: "0-20级注记",
      url: "http://t{0-6}.tianditu.gov.cn/cva_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "cva",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      })
    })
    return [source1, source2];
  },

  /**
   * 天地图影像
   */
  getTDImageLayerXYZ: function () {
    let projection = mapApi.proj.get("EPSG:4326");
    let projectionExtent = projection.getExtent();
    let size = mapApi.olExtent.getWidth(projectionExtent) / 256;
    let resolutions = [];

    for (let z = 2; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z);
    }

    let source1 = new mapApi.WMTSSource({
      name: "0-20级影像底图",
      url: "http://t{0-6}.tianditu.gov.cn/img_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "img",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      })
    })


    let source2 = new mapApi.WMTSSource({
      name: "0-20级影像注记",
      url: "http://t{0-6}.tianditu.gov.cn/cia_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "cia",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      })
    })
    return [source1, source2];
  },

  getDGImage: function () {
    let source1 = new mapApi.XYZSource({
      crossOrigin: "anonymous",
      url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=6",
    })

    let source2 = new mapApi.XYZSource({
      crossOrigin: "anonymous",
      url: "http://wprd0{1-4}.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=8",
    })
    return [source1, source2]
  },

  getOSMvector: function () {
    let source1 = new mapApi.XYZSource({
      crossOrigin: "anonymous",
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    })
    return [source1, null]
  },

  /**
 * 获取arcgis切片（内网）
 */
  getArcGisImageTitl: function () {
    return [
      new mapApi.TileArcGISRest({
        url: "http://ags.bhfj.gov.cn/server/rest/services/GE/MapServer"
      })
    ]
  },

  /**
 * 天地图矢量 变色
 */
  getTDvecLayerXYZ_blue: function () {
    let projection = mapApi.proj.get("EPSG:4326");
    let projectionExtent = projection.getExtent();
    let size = mapApi.olExtent.getWidth(projectionExtent) / 256;
    let resolutions = [];

    for (let z = 2; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z);
    }

    let source1 = new mapApi.WMTSSource({
      name: "0-20级底图",
      url: "http://t{0-6}.tianditu.gov.cn/vec_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "vec",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      }),
      tileLoadFunction: function (imageTile, src) {
        let img = new Image();
        img.crossOrigin = '';
        img.onload = function () {
          let canvas = document.createElement('canvas');
          let w = img.width;
          let h = img.height;
          canvas.width = w;
          canvas.height = h;
          let context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
          let imageData = context.getImageData(0, 0, w, h);
          for (let i = 0; i < imageData.height; i++) {
            for (let j = 0; j < imageData.width; j++) {
              var x = (i * 4) * imageData.width + (j * 4);
              var r = imageData.data[x];
              var g = imageData.data[x + 3];
              var b = imageData.data[x + 2];
              var grey = r * 0 + g * 0.3 + b * 0.7;
              //将rgb的值替换为灰度值
              imageData.data[x] = 55 - grey;
              imageData.data[x + 1] = 262 - grey;
              imageData.data[x + 2] = 280 - grey;
            }
          }
          context.putImageData(imageData, 0, 0);
          imageTile.getImage().src = canvas.toDataURL('image/png');
        }
        img.src = src;
      },
    });
    let source2 = new mapApi.WMTSSource({
      type: "baseMap",
      name: "0-20级注记",
      url: "http://t{0-6}.tianditu.gov.cn/cva_c/wmts?tk=934635cfe0abf0a0fdec4c045c327ae9",
      layer: "cva",
      style: "default",
      matrixSet: "c",
      format: "tiles",
      wrapX: true,
      tileGrid: new mapApi.WMTSTileGrid({
        origin: mapApi.olExtent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20
        ]
      }),
      // tileLoadFunction: function (imageTile, src) {
      //   let img = new Image();
      //   img.crossOrigin = '';
      //   img.onload = function () {
      //     let canvas = document.createElement('canvas');
      //     let w = img.width;
      //     let h = img.height;
      //     canvas.width = w;
      //     canvas.height = h;
      //     let context = canvas.getContext('2d');
      //     context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
      //     let imageData = context.getImageData(0, 0, w, h);
      //     for (let i = 0; i < imageData.height; i++) {
      //       for (let j = 0; j < imageData.width; j++) {
      //         var x = (i * 4) * imageData.width + (j * 4);
      //         var r = imageData.data[x];
      //         var g = imageData.data[x + 1];
      //         var b = imageData.data[x + 2];
      //         var grey = r * 0.3 + g * 0.59 + b * 0.11;
      //         //将rgb的值替换为灰度值
      //         imageData.data[x] = 55 - grey;
      //         imageData.data[x + 1] = 34 - grey;
      //         imageData.data[x + 2] = 72 - grey;
      //       }
      //     }
      //     context.putImageData(imageData, 0, 0);
      //     imageTile.getImage().src = canvas.toDataURL('image/png');
      //   }
      //   img.src = src;
      // },
    })
    return [source1, source2];
  },

}

export default baseMapSource;