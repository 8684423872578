/*
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2023-08-24 09:50:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-08-25 16:18:17
 */
import { Vector as VectorSource } from 'ol/source.js';
import VectorLayer from "ol/layer/Vector";
import LineString from 'ol/geom/LineString.js';
import Feature from 'ol/Feature.js';
import Overlay from 'ol/Overlay.js';
import { Stroke, Style } from 'ol/style.js'
import DragPan from 'ol/interaction/DragPan.js';

export default class popupMove {
    constructor(map, popup) {

        this.map = map;
        this.close = this.close.bind(this);
        this.lineLayer = this.getLineLayer(map);
        this.lineFeature = this.getLineFeature(this.lineLayer);
        this.mapPopupDom = null;
        this.mapPopupVm = null;
        this.overlayForm = null;
        this.addOverlay(popup);
        this.eleMosueDownFun = null;
        this.mapPointermoveFun = null;
        this.mapPointerupFun = null;
        this.loadEvents();
        this.fromCoord = [1000, 1000]
    }

    show(coord) {
        this.fromCoord = coord;
        this.overlayForm.set("coordinate", coord);
        this.overlayForm.setPosition(this.fromCoord)
    }
    close() { 
        this.lineLayer.getSource().removeFeature(this.lineFeature);
        this.map.removeOverlay(this.overlayForm);
        this.mapPopupDom.removeEventListener('mousedown', this.eleMosueDownFun);
        this.map.un('pointermove', this.mapPointermoveFun);
        this.map.un('pointerup', this.mapPointerupFun);
    }

    getLineLayer(map) {
        let layers = map.getLayers();
        let layer;
        layers.forEach(e => {
            if (e.id == "popupLigatureLayer") {
                layer = e;
            }
        });
        if (!layer) {
            layer = new VectorLayer({
                id: "popupLigatureLayer",
                visible: true,
                updateWhileInteracting: true,
                source: new VectorSource({
                    projection: "EPSG:4326",
                }),
                style: new Style({
                    stroke: new Stroke({
                        color: '#ffc600',
                        width: 1,
                        // lineDash: [12, 12, 12, 12],
                    }),
                }),
            });
            map.addLayer(layer);
        }
        return layer;
    }

    getLineFeature(layer) {
        var line = new LineString([1000, 1000], [1000, 1000]);
        var lineFeature = new Feature(line);
        layer.getSource().addFeature(lineFeature);
        return lineFeature;
    }

    addOverlay(popup) {
        this.mapPopupVm = new popup({}).$mount(); //根据模板创建一个面板
        this.mapPopupVm.closeCallback = this.close;
        let target = this.map.getTarget();
        this.mapPopupDom = document.getElementById(target).appendChild(this.mapPopupVm.$el);

        // 创建一个气泡
        this.overlayForm = new Overlay({
            element: this.mapPopupVm.$el,
            positioning: 'center-left',
            // autoPan: true,
            // autoPanAnimation: {
            //     duration: 250,
            // },
        })
        this.overlayForm.setPosition(undefined)   // 设置弹窗位置，刚开始我们不让他显示，就是undefined就行
        this.map.addOverlay(this.overlayForm)  // 然后把弹窗的图层加载到地图上
        this.overlayForm.setPositioning("bottom-left")
    }

    loadEvents() {
        let th = this;
        let dragPan;
        th.map.getInteractions().forEach(function (interaction) {
            if (interaction instanceof DragPan) {
                dragPan = interaction;
            }
        });
        let offsetXY = [0, 0];
        this.eleMosueDownFun = function (evt) {
            dragPan.setActive(false);
            th.overlayForm.set('dragging', true);
            //获取事件点击的坐标值
            let evtCoord = th.map.getEventCoordinate(evt);
            let domCoord = th.overlayForm.get("coordinate");
            //计算鼠标点击位置和基点坐标(可能是div的左上角)之间的偏移量
            offsetXY = [evtCoord[0] - domCoord[0], evtCoord[1] - domCoord[1]];
        }
        this.mapPopupDom.addEventListener('mousedown', this.eleMosueDownFun);

        this.mapPointermoveFun = function (evt) {
            var startPoint = th.fromCoord;
            if (th.overlayForm.get('dragging')) {
                //将当前的鼠标位置减去偏移量，得到基点应该所处的位置
                let coor = [evt.coordinate[0] - offsetXY[0], evt.coordinate[1] - offsetXY[1]];
                th.overlayForm.setPosition(coor);
                th.overlayForm.set("coordinate", coor);

                th.lineFeature.getGeometry().setCoordinates([startPoint, coor]);
            }
        }
        this.map.on('pointermove', this.mapPointermoveFun);

        this.mapPointerupFun = function (evt) {
            if (th.overlayForm.get('dragging') === true) {
                dragPan.setActive(true);
                th.overlayForm.set('dragging', false);
            }
        }
        this.map.on('pointerup', this.mapPointerupFun);
    }

}