<!--
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2023-08-25 15:15:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-01 15:28:40
-->

<template>
  <div :id="id" class="divlabel-container-map" v-if="show">
    <div class="map_popup_info">
      <img class="map_popup_close" src="../../assets/map/out.png" @click="close"/>
      <div class="map_popup_info_title">{{ title }}</div>
      <table class="map_popup_info_list">
        <tr
          class="map_popup_info_element"
          v-for="(item, index) in property"
          :key="index"
        >
          <td id="name">{{ item.name }}</td>
          <td id="value">{{ item.value }}{{ item.unit }}</td>
        </tr>
      </table>
    </div>
    <!-- <div class="earth_popup_corner" v-if="cornerShow">
      <div class="corner" />
    </div> -->
  </div>
</template>
<script>
export default {
  name: "DynamicLabel",
  components: {},
  data() {
    return {
      show: true,
      cornerShow: true,
      id: "1",
      closeCallback: function () {
        console.log("关闭时间未挂载");
      },
      closeCallback_bus: function () {
        console.log("关闭时间未挂载");
      },
      property: [{ name: "asd", value: "s12" }],
      title: "tre",
    };
  },
  watch: {
    // property(newvalue, oldvalue) {
    //   console.log(newvalue, oldvalue);
    // },
  },
  mounted() {},
  methods: {
    close() {
      this.closeCallback();
      this.closeCallback_bus();
      this.show = false;
      this.cornerShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.divlabel-container-map {
  .map_popup_info {
    background: rgba(51, 0, 0, 0.678);
    border: 1px solid #ff0024;

    .map_popup_close {
      float: right;
      margin: 6px 6px 0px 6px;
      pointer-events: auto;
      cursor: pointer;
    }
    .map_popup_info_title {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
      padding: 6px 0px 7px 6px;
      background: #7b1016;
      border-bottom: 1px solid #ff0024;
    }
    .map_popup_info_list {
      padding: 0px;
      margin: 0px;
      border-collapse: collapse;
      display: grid;
      padding: 7px 8px 7px 8px;
      .map_popup_info_element {
        padding: 0px ;
        td {
          vertical-align: middle;
          align-self: center;
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: white;
        }
        #name{
          width: 60px;
          height: 100%;
          text-align: left;
          padding: 6px 0px 6px 9px;
        }
        #value {
          width: 170px;
          height: 100%;
          text-align: left;
          padding: 4px 0px 4px 6px;
        }
      }
    }
  }
}

.map_popup_corner {
  width: 10px;
  height: 10px;
  margin: auto;
  .corner {
    border-top: 10px solid #375d9fd0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}
</style>
